<template>
  <div class="grey800 px-4 py-5 d-flex align-center">
    <h4 class="text-body-1 mr-1">
      <fai :icon="['fad', 'crown']" class="mr-2 primary500--text" />
      <span v-if="user.role" class="text-capitalize grey100--text" v-text="user.role" />
      <em v-else class="grey200--text">no role</em>
    </h4>
    <em v-if="roles[user.role]" class="text-caption grey200--text">
      ({{ roles[user.role].fullPerms.length }} perms)
    </em>
    <span v-if="user.permissions" class="ml-3 text-body-2 blue400--text">
      + {{ permissions.length }} user perms
    </span>
    <v-spacer />
    <v-btn
      color="primary700" tile small
      :ripple="false" :loading="loading"
      @click="onEditBtnClick"
    >
      Edit Permissions
    </v-btn>
  </div>
</template>

<script>
import { getPermissionsList, getRolePermissions } from '@/utils/constants/permissions'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    rolesData: null,
    loading: false,
  }),
  computed: {
    roles() {
      const { rolesData: data } = this

      if (!data) return {}

      const roles = data.reduce((acc, v) => {
        acc[v.name] = {
          permissions: v.permissions,
          inherits: v.inherits,
        }
        return acc
      }, {})

      return data.reduce((acc, v) => {
        const perms = getPermissionsList(v.permissions)
        const rolePermissions = getRolePermissions(v.name, roles)
        const rolePerms = getPermissionsList(rolePermissions)
        const fullPermissions = v.permissions | rolePermissions
        const fullPerms = getPermissionsList(fullPermissions)

        acc[v.name] = {
          permissions: v.permissions,
          inherits: v.inherits,
          perms,
          rolePermissions,
          rolePerms,
          fullPermissions,
          fullPerms,
        }
        return acc
      }, {})
    },
    permissions() {
      return getPermissionsList(this.user?.permissions ?? 0)
    },
  },
  created() {
    this.getRoles()
  },
  methods: {
    async getRoles() {
      this.loading = true

      try {
        this.rolesData = await this.$socket.request('admin.roles.list')
      } catch (error) {
        this.$toast.error(`Couldn't get roles data: ${error.message}`)
      } finally {
        this.loading = false
      }
    },
    async onEditBtnClick() {
      const modal = this.$modal.open(
        'admin-user-permissions',
        { hasReturnValue: true },
        { roles: this.roles, user: this.user },
      )
      const form = await modal.promise

      if (!form) return

      this.$emit('update', {
        steamid: this.user.steamid,
        ...form,
      })
    },
  },
}
</script>
