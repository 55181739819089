<template>
  <div class="grey800 px-4 py-5 d-flex">
    <v-autocomplete
      v-model="form"
      :items="flairs"
      class="rounded-0 mr-2" background-color="grey600"
      hide-details small-chips chips deletable-chips
      multiple clearable solo flat
      item-text="name" item-value="_id"
    />

    <div class="d-flex justify-end">
      <v-btn
        color="primary700" tile height="48"
        :ripple="false" :loading="loading"
        :disabled="!changed"
        @click="onSet"
      >
        Set
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    flairs: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: [...this.value],
    }
  },
  computed: {
    changed() {
      return JSON.stringify(this.value) !== JSON.stringify(this.form)
    },
  },
  watch: {
    value(val) {
      this.form = [...val]
    },
  },
  methods: {
    async onSet() {
      this.$emit('set', [...this.form])
    },
  },
}
</script>
