<template>
  <v-tooltip v-bind="$attrs" color="grey700" :disabled="!nextLevelXp">
    <template #activator="{on}">
      <div class="user-xp d-flex align-center" v-on="on">
        <div class="xp-bar of-hidden rounded-pill flex-grow-1 mr-3 grey700">
          <div class="inner-bar primary500" :style="{transform: `scaleX(${xpBarProgress})`}" />
        </div>
        <div v-if="!noRemainder" class="xp-remainder primary300--text text-caption">
          <span v-if="nextLevelXp">{{ nextLevelXp | toLocaleInt }} XP</span>
          <span v-else class="text-uppercase" v-text="'max level'">MAX LEVEL</span>
        </div>
      </div>
    </template>
    <span class="grey100--text font-weight-semibold">
      {{ nextLevelXp - xp | toLocaleInt }}
      <span v-text="'XP remaining'" />
    </span>
  </v-tooltip>
</template>

<script>
import { LEVELS } from '@/utils/constants'

export default {
  props: {
    xp: {
      type: Number,
      default: 0,
    },
    noRemainder: Boolean,
  },
  computed: {
    level() {
      const nextLevel = LEVELS.findIndex(lvl => lvl > this.xp)
      return (nextLevel !== -1 ? nextLevel : LEVELS.length) - 1
    },
    currentLevelXp() {
      return LEVELS?.[this.level] ?? 0
    },
    nextLevelXp() {
      return LEVELS?.[this.level + 1]
    },
    xpBarProgress() {
      if (!this.nextLevelXp) return 1

      const progress = (this.xp - this.currentLevelXp) / (this.nextLevelXp - this.currentLevelXp)
      return Math.max(progress, 0.05)
    },
  },
}
</script>

<style lang="scss" scoped>
.xp-bar {
  position: relative;
  height: 8px;

  .inner-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.6s ease;
    transform-origin: center left;
  }
}
</style>
