<template>
  <div>
    <v-alert
      v-for="ban in userBans" :key="ban.type" tile dense
      color="primary700 grey100--text mb-3"
    >
      <div class="d-flex align-center">
        <fai :icon="['fad', ban.icon]" :title="ban.type" class="text-h5 mr-6 ml-2" />
        <div v-if="ban.info" class="flex-grow-1">
          <h5 class="text-body-2 d-flex align-center mb-1">
            <fai :icon="['fad', 'clock']" class="text-body-1 grey100--text mr-2" />
            <span class="mt-1">Expiration: {{ ban.info.expiration | relativeTime }}</span>
          </h5>
          <p class="mb-0 text-caption">
            Reason: "{{ ban.info.reason }}"
          </p>
          <p class="mb-0 text-caption">
            Banned by: {{ ban.info.createdBy }}
          </p>
        </div>
        <div v-else class="flex-grow-1 text-body-2">
          No ban info found or ban has expired.
        </div>
        <v-btn
          color="primary800" tile small depressed
          :ripple="false" :loading="loading"
          @click="$emit('unban', { user, type: ban.type })"
        >
          Remove
        </v-btn>
      </div>
    </v-alert>

    <div class="grey800 px-4 py-5 d-flex align-center">
      <h4 v-if="!userBans.length" class="text-body-2 text-center grey200--text">
        User does not have any active bans.
      </h4>
      <v-spacer />
      <v-btn
        color="primary700" tile small
        :ripple="false" :loading="loading"
        @click="onBanBtnClick"
      >
        {{ userBans.length ? 'Update or add ban' : 'Ban user' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { BAN_FLAGS } from '@/utils/constants'
import { invertObject } from '@/utils'

const BAN_TYPE_ICONS = {
  full: 'ban',
  chat: 'comment-slash',
  bet: 'coins',
}

const FLAGS_BAN = invertObject(BAN_FLAGS)

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userBans() {
      const { user } = this
      if (!user) return []

      const bansInfo = user.bansInfo.reduce((acc, v) => {
        acc[FLAGS_BAN[v.type]] = v
        return acc
      }, {})

      const bans = Object.entries(BAN_FLAGS).map(([k, v]) => ({
        type: k,
        active: !!(user.bans & v),
        icon: BAN_TYPE_ICONS[k],
        info: bansInfo[k],
      }))

      return bans.filter(i => i.active)
    },
  },
  methods: {
    async onBanBtnClick() {
      const modal = this.$modal.open(
        'admin-user-ban',
        { hasReturnValue: true, maxWidth: 400 },
        { user: this.user },
      )
      const form = await modal.promise

      if (!form) return

      this.$emit('ban', {
        ...form,
        steamid: this.user.steamid,
      })
    },
  },
}
</script>
