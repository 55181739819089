<template>
  <div>
    <div class="d-flex justify-end mb-2">
      <v-btn
        color="primary700 primary200--text"
        class="mr-3"
        tile small
        :ripple="false" :loading="loading"
        :disabled="!total"
        @click="onDelete(entries.map(i => i._id))"
      >
        <fai :icon="['fad','trash']" class="primary100--text mr-2" />
        Delete all visible
      </v-btn>

      <v-btn
        color="grey300"
        tile small
        :ripple="false" :loading="loading"
        @click="fetchApiData"
      >
        <fai :icon="['fas','sync']" class="grey100--text" />
      </v-btn>
    </div>

    <v-data-table
      class="rounded-0 data-table"
      item-key="_id"
      :headers="headers"
      :items="entries"
      :options.sync="options"
      :footer-props="{itemsPerPageOptions: [5, 10, 25]}"
      :server-items-length="total"
      :loading="loading"
      :expanded="expanded"
      @pagination="onPagination"
    >
      <template #item.data="{ value }">
        <span class="text-caption text--secondary">{{ JSON.stringify(value) }}</span>
      </template>

      <template #item.expiresAt="{ value }">
        <span v-if="!value">-</span>
        <v-tooltip v-else top color="primary500">
          <template #activator="{on}">
            <span v-on="on">{{ value | relativeTime('twitter') }}</span>
          </template>
          <span>{{ value | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
        </v-tooltip>
      </template>

      <template #item.createdAt="{ value }">
        <v-tooltip top color="primary500">
          <template #activator="{on}">
            <span v-on="on">{{ value | relativeTime('twitter') }}</span>
          </template>
          <span>{{ value | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
        </v-tooltip>
      </template>

      <template #item.actions="{ item }">
        <fai
          :icon="['fad', 'info-square']"
          class="text-body-1 mt-1 blue500--text link mr-2"
          @click="onExpandItem(item)"
        />
        <fai
          :icon="['fad', 'trash']"
          class="text-body-1 mt-1 primary500--text link"
          @click="onDelete([item._id])"
        />
      </template>

      <!-- transaction info -->
      <template #expanded-item="{ headers: head, item }">
        <td :colspan="head.length" class="grey800 py-3 data-detail">
          <v-expansion-panels accordion flat tile>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey600">
                Full reward object
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey600">
                <pre v-text="item" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    steamid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      expanded: [],
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 5,
        mustSort: true,
      },
      headers: [
        { text: 'Type', value: 'type', align: 'start' },
        {
          text: 'Data', value: 'data', sortable: false, align: 'start',
        },
        { text: 'Expiration', value: 'expiresAt', align: 'end' },
        { text: 'Time', value: 'createdAt', align: 'end' },
        {
          text: '', value: 'actions', align: 'end', width: 80,
        },
      ],
      entries: [],
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
  },
  methods: {
    async fetchApiData() {
      this.loading = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.user.rewards', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          steamid: this.steamid,
        })

        this.entries = entries
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item._id === expandedItem?._id) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    async onDelete(ids) {
      this.loading = true
      try {
        await this.$socket.request('admin.user.rewards.delete', ids)
        this.$toast.success('User reward(s) removed')
        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    onPagination() {
      this.expanded = []
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }

  .v-data-table__expanded__row {
    td {
      border-bottom: 0 !important;
    }
  }

  .transaction-detail-container {

  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
