<template>
  <div>
    <v-row dense class="mb-4">
      <v-col v-for="(stat, label) in userStats" :key="label" cols="12">
        <v-sheet color="grey700" class="px-4 py-2 full-height d-flex align-center">
          <h2 class="text-body-2 font-weight-semibold grey200--text lh-1" v-text="label" />
          <v-spacer />
          <span class="text-caption">{{ stat | toScrap }}</span>
        </v-sheet>
      </v-col>
    </v-row>

    <div v-for="(game, gameName) in gameStats" :key="gameName" class="mb-4">
      <h4 class="text-body-2 text-uppercase mb-2 d-flex align-center">
        <span class="lh-1 grey100--text font-weight-semibold" v-text="gameName" />
      </h4>

      <v-row dense>
        <v-col v-for="(stat, label) in game" :key="label" cols="12">
          <v-sheet color="grey700" class="px-4 py-2 full-height d-flex align-center">
            <h2 class="text-body-2 font-weight-semibold grey200--text lh-1" v-text="label" />
            <v-spacer />
            <span class="text-caption">{{ stat | toScrap }}</span>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    userStats() {
      const { stats } = this

      return {
        deposited: stats?.depositSum ?? 0,
        bet: stats?.betSum ?? 0,
        scrapBetSum: stats?.scrapBetSum ?? 0,
        won: stats?.winSum ?? 0,
        faucet: stats?.faucetSum ?? 0,
        withdraw: stats?.withdrawSum ?? 0,
      }
    },
    gameStats() {
      const { stats } = this

      return {
        wheel: {
          bet: stats?.games?.wheel?.betSum ?? 0,
          won: stats?.games?.wheel?.winSum ?? 0,
        },
        spinners: {
          bet: stats?.games?.spinners?.betSum ?? 0,
          won: stats?.games?.spinners?.winSum ?? 0,
        },
        coinflip: {
          bet: stats?.games?.coinflip?.betSum ?? 0,
          won: stats?.games?.coinflip?.winSum ?? 0,
        },
        cases: {
          bet: stats?.games?.cases?.betSum ?? 0,
          won: stats?.games?.cases?.winSum ?? 0,
        },
        dice: {
          bet: stats?.games?.dice?.betSum ?? 0,
          won: stats?.games?.dice?.winSum ?? 0,
        },
        caseBattles: {
          bet: stats?.games?.caseBattles?.betSum ?? 0,
          won: stats?.games?.caseBattles?.winSum ?? 0,
        },
      }
    },
  },
}
</script>
