var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-end mb-2"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary700 primary200--text","tile":"","small":"","ripple":false,"loading":_vm.loading,"disabled":!_vm.total},on:{"click":function($event){_vm.onDelete(_vm.entries.map(function (i) { return i._id; }))}}},[_c('fai',{staticClass:"primary100--text mr-2",attrs:{"icon":['fad','trash']}}),_vm._v(" Delete all visible ")],1),_c('v-btn',{attrs:{"color":"grey300","tile":"","small":"","ripple":false,"loading":_vm.loading},on:{"click":_vm.fetchApiData}},[_c('fai',{staticClass:"grey100--text",attrs:{"icon":['fas','sync']}})],1)],1),_c('v-data-table',{staticClass:"rounded-0 data-table",attrs:{"item-key":"_id","headers":_vm.headers,"items":_vm.entries,"options":_vm.options,"footer-props":{itemsPerPageOptions: [5, 10, 25]},"server-items-length":_vm.total,"loading":_vm.loading,"expanded":_vm.expanded},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"text-caption text--secondary"},[_vm._v(_vm._s(JSON.stringify(value)))])]}},{key:"item.expiresAt",fn:function(ref){
var value = ref.value;
return [(!value)?_c('span',[_vm._v("-")]):_c('v-tooltip',{attrs:{"top":"","color":"primary500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("relativeTime")(value,'twitter')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateFormat")(value,'YYYY-MM-DD HH:mm:ss')))])])]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("relativeTime")(value,'twitter')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateFormat")(value,'YYYY-MM-DD HH:mm:ss')))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('fai',{staticClass:"text-body-1 mt-1 blue500--text link mr-2",attrs:{"icon":['fad', 'info-square']},on:{"click":function($event){return _vm.onExpandItem(item)}}}),_c('fai',{staticClass:"text-body-1 mt-1 primary500--text link",attrs:{"icon":['fad', 'trash']},on:{"click":function($event){return _vm.onDelete([item._id])}}})]}},{key:"expanded-item",fn:function(ref){
var head = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"grey800 py-3 data-detail",attrs:{"colspan":head.length}},[_c('v-expansion-panels',{attrs:{"accordion":"","flat":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"grey600"}},[_vm._v(" Full reward object ")]),_c('v-expansion-panel-content',{attrs:{"color":"grey600"}},[_c('pre',{domProps:{"textContent":_vm._s(item)}})])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }