<template>
  <div class="give-items grey800 px-3 py-2 d-flex">
    <div class="shop d-flex flex-column flex-grow-1 mr-3">
      <div class="d-flex mb-3">
        <!-- search -->
        <v-text-field
          :value="inputs.search"
          class="mr-2 rounded-0 search-input"
          background-color="grey400"
          :placeholder="$t('common.search')"
          hide-details solo flat dense
          @input="onSearchInput"
        />

        <!-- refresh -->
        <v-btn
          color="blue700" class="ma-0 rounded-0 unwidth unheight"
          :ripple="false" tile small :disabled="loading.inventory"
          @click="getInv"
        >
          <fai
            class="blue300--text text-body-2"
            :spin="loading.inventory"
            :icon="['fas','sync']"
          />
        </v-btn>
      </div>

      <Inventory
        class="flex-grow-1"
        :loading="loading.inventory"
        :items="filteredItems"
        :item-component="itemComponent"
        @onItemClick="onItemClick"
        @onItemAmountChange="changeItemAmount"
      />
    </div>
    <div class="cart d-flex flex-column">
      <div class="cart-items flex-grow-1">
        <div
          v-for="item in selectedItems" :key="item.id"
          class="cart-item grey600 d-flex align-center mb-1 px-2 py-3"
        >
          <div class="item-info flex-grow-1 d-flex align-center">
            <div class="grey700 pos-relative remove-container pa-2 flex-grow-0 mr-3">
              <v-img
                :src="`https://community.cloudflare.steamstatic.com/economy/image/${item.image}/164x164`"
                aspect-ratio="1" width="40"
              />

              <a class="pa-4 d-flex align-center justify-center flex-column remove-btn" @click="onItemRemove(item)">
                <fai class="primary100--text text-h5 mb-1" :icon="['far', 'times-circle']" />
              </a>
            </div>
            <div class="grey100--text text-caption">
              <div v-if="item.amountSelected > 1" class="d-block text-body-2 font-weight-bold primary500--text">
                {{ item.amountSelected }}x
              </div>
              <div class="mb-1">
                {{ item.name }}
              </div>

              <div class="d-block item-flags text-caption d-flex align-center px-1">
                <v-tooltip
                  v-for="(flag, flagKey) in flags" :key="flag.tooltip"
                  top open-delay="300"
                  content-class="rounded-0"
                  color="grey700"
                  transition="v-scale-sorta"
                >
                  <template #activator="{on}">
                    <fai
                      class="item-flag mr-1 link"
                      :icon="flag.icon"
                      :class="[flag.classes, !item[flagKey] && 'text--disabled']"
                      fixed-width
                      v-on="on"
                      @click="item[flagKey] = !item[flagKey]"
                    />
                  </template>
                  <span v-t="flag.tooltip" class="text-caption" />
                </v-tooltip>
              </div>
            </div>
          </div>
          <div class="blue300--text px-1 text-caption font-weight-semibold">
            ${{ item.price * item.amountSelected | toScrap }}
          </div>
        </div>
      </div>


      <v-btn
        class="flex-grow-0 text-none"
        color="primary700 primary200--text"
        tile depressed block
        :ripple="false"
        :loading="loading.give"
        :disabled="!selectedCount"
        @click="onGive"
      >
        Give {{ selectedCount }} items (${{ selectedValue | toScrap }})
      </v-btn>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash.sortby'
import { mapGetters } from 'vuex'
import { debounce } from '@/utils'

import Inventory from '@/components/Inventory'
import ShopItem from '@/components/ShopItem'

const FLAGS = {
  canTip: {
    tooltip: 'common.item.can_tip',
    icon: ['fad', 'hand-holding-heart'],
    classes: 'green400--text',
  },
  canSell: {
    tooltip: 'common.item.can_sell',
    icon: ['fad', 'coins'],
    classes: 'blue300--text',
  },
  canWithdraw: {
    tooltip: 'common.item.can_withdraw',
    icon: ['fad', 'arrow-alt-square-up'],
    classes: 'yellow--text',
  },
  canBet: {
    tooltip: 'common.item.can_bet',
    icon: ['fad', 'gamepad-alt'],
    classes: 'primary400--text',
  },
}

export default {
  components: {
    Inventory,
  },
  props: {
    steamid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        inventory: false,
        give: false,
      },
      inputs: {
        search: '',
      },
      items: [],
    }
  },
  computed: {
    ...mapGetters({
      conga: 'conga/conga',
      itemdb: 'items/items',
    }),
    itemComponent() {
      return ShopItem
    },
    flags() {
      return FLAGS
    },
    filteredItems() {
      let items = [...this.items]
      const { search } = this.inputs

      if (search) {
        items = items.filter(i => (i?.name ?? '').toLowerCase().includes(search.toLowerCase()))
      }

      return items
    },
    selectedItems() {
      return this.items.filter(i => i.selected)
    },
    selectedCount() {
      return this.selectedItems.reduce((acc, i) => acc + i.amountSelected, 0)
    },
    selectedValue() {
      return this.selectedItems.reduce((acc, i) => acc + i.price * i.amountSelected, 0)
    },
  },
  created() {
    this.getInv()
  },
  methods: {
    async getInv() {
      this.loading.inventory = true

      const { itemdb } = this

      try {
        const items = await this.$socket.request('user.inventory.swap.listings')

        this.items = sortBy(
          items
            .map(item => {
              if (!itemdb[item.name]) return false

              return {
                ...itemdb[item.name],
                id: item.name, // only one of each item in this endpoint
                name: item.name,
                price: item.price,
                amount: item.count,
                selected: false,
                amountSelected: 1,
                inStock: item.inStock,
                canWithdraw: false,
                canSell: true,
                canBet: true,
                canTip: false,
              }
            })
            .filter(Boolean),
          'price',
        ).reverse()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.inventory = false
      }
    },
    async onGive() {
      if (this.loading.give) return

      const selected = this.selectedItems.map(i => ({
        name: i.name,
        count: i.amountSelected,
        canWithdraw: i.canWithdraw,
        canSell: i.canSell,
        canBet: i.canBet,
        canTip: i.canTip,
      }))

      if (!selected.length) return

      try {
        this.loading.give = true
        await this.$socket.request('admin.user.giveItems', {
          steamid: this.steamid,
          items: selected,
        })
        this.$toast.success('Items given to user.')

        // eslint-disable-next-line no-param-reassign
        this.selectedItems.forEach(i => { i.selected = false })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.give = false
      }
    },
    onItemClick(item) {
      if (this.loading.withdraw) return

      // eslint-disable-next-line no-param-reassign
      item.selected = !item.selected
    },
    changeItemAmount(item, val) {
      if (item.amount < 2) return

      // eslint-disable-next-line no-param-reassign
      item.amountSelected = val

      // eslint-disable-next-line no-param-reassign
      item.selected = true
    },
    onItemRemove(item) {
      if (this.loading.withdraw) return

      // eslint-disable-next-line no-param-reassign
      item.selected = false
    },
    onSearchInput: debounce(function onChange(search) {
      this.inputs.search = search.trim()
    }, 200),

  },
}
</script>


<style lang="scss" scoped>
.give-items {
  height: 500px;

  .shop {
    overflow: hidden;
  }

  .cart {
    min-width: 250px;
    max-width: 250px;

    .cart-items {
      overflow-y: auto;
    }

    .cart-item {
      .remove-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: rgba(primary(500), 0.75);
        transition: opacity 0.3s ease, transform 0.3s ease;
        transform: scale(0.7);
      }

      &:hover {
        .remove-btn {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
</style>
