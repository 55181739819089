<template>
  <div class="user-inventory grey800 px-3 py-2 d-flex d-flex flex-column">
    <div class="d-flex mb-3">
      <div class="grey500 text-caption d-flex align-center px-3 mr-2">
        <span class="mr-1">{{ items.length }} items</span>
        <span class="blue300--text">(${{ itemsValue | toScrap }})</span>
      </div>

      <!-- search -->
      <v-text-field
        :value="inputs.search"
        class="mr-2 rounded-0 search-input"
        background-color="grey400"
        :placeholder="$t('common.search')"
        hide-details solo flat dense
        @input="onSearchInput"
      />

      <!-- Remove -->
      <v-btn
        v-if="selectedItems.length"
        color="primary700 primary300--text" class="mr-2 unheight"
        :ripple="false" tile small
        :loading="loading.remove"
        @click="onRemove"
      >
        <fai
          class="primary200--text mr-2"
          :icon="['fad','trash']"
        />
        Remove
      </v-btn>

      <!-- select all -->
      <v-btn
        color="grey400 grey200--text mr-2" class="unwidth unheight"
        :ripple="false" tile small
        @click="onSelectAll"
      >
        <fai
          class="text-body-2"
          :icon="['fad','copy']"
        />
      </v-btn>

      <!-- refresh -->
      <v-btn
        color="blue700" class="unwidth unheight"
        :ripple="false" tile small :disabled="loading.inventory"
        @click="getInv"
      >
        <fai
          class="blue300--text text-body-2"
          :spin="loading.inventory"
          :icon="['fas','sync']"
        />
      </v-btn>
    </div>

    <Inventory
      class="flex-grow-1"
      :loading="loading.inventory"
      dollar-value
      :items="filteredItems"
      :item-component="itemComponent"
      @onItemClick="onItemClick"
    />
  </div>
</template>

<script>
import sortBy from 'lodash.sortby'
import { mapGetters } from 'vuex'
import { debounce } from '@/utils'

import Inventory from '@/components/Inventory'
import BackpackItem from '@/components/BackpackItem'

export default {
  components: {
    Inventory,
  },
  props: {
    steamid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        inventory: false,
        remove: false,
      },
      inputs: {
        search: '',
      },
      items: [],
    }
  },
  computed: {
    ...mapGetters({
      conga: 'conga/conga',
      itemdb: 'items/items',
    }),
    itemComponent() {
      return BackpackItem
    },
    itemsValue() {
      return this.items.reduce((acc, i) => acc + i.price, 0)
    },
    filteredItems() {
      let items = [...this.items]
      const { search } = this.inputs

      if (search) {
        items = items.filter(i => (i?.name ?? '').toLowerCase().includes(search.toLowerCase()))
      }

      return items
    },
    selectedItems() {
      return this.items.filter(i => i.selected)
    },
    selectedValue() {
      return this.selectedItems.reduce((acc, i) => acc + i.price, 0)
    },
  },
  created() {
    this.getInv()
  },
  methods: {
    async getInv() {
      this.loading.inventory = true

      const { itemdb } = this

      try {
        const resp = await this.$socket.request('admin.user.inventory', this.steamid)
        const items = resp.map(i => new Item({ ...i, ...itemdb[i.name] }))
        this.items = sortBy(items, 'price').reverse()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.inventory = false
      }
    },
    async onRemove() {
      if (this.loading.remove) return
      if (!this.selectedItems.length) return

      try {
        this.loading.remove = true

        await this.$socket.request('admin.user.removeItems', {
          steamid: this.steamid,
          items: this.selectedItems.map(i => i.id),
        })

        this.$toast.success('Items removed from user')

        this.getInv()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.remove = false
      }
    },
    onItemClick(item) {
      // eslint-disable-next-line no-param-reassign
      item.selected = !item.selected
    },
    changeItemAmount(item, val) {
      if (item.amount < 2) return

      // eslint-disable-next-line no-param-reassign
      item.amountSelected = val

      // eslint-disable-next-line no-param-reassign
      item.selected = true
    },
    onItemRemove(item) {
      if (this.loading.withdraw) return

      // eslint-disable-next-line no-param-reassign
      item.selected = false
    },
    onSearchInput: debounce(function onChange(search) {
      this.inputs.search = search.trim()
    }, 200),
    onSelectAll() {
      const allSelected = this.items.every(i => i.selected)

      this.items.forEach(i => {
        // eslint-disable-next-line no-param-reassign
        i.selected = !allSelected
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-inventory {
  overflow: hidden;
  max-height: 400px;
}
</style>
