<template>
  <div class="grey800 px-4 py-5 d-flex">
    <v-select
      v-model="form.field"
      :items="fields"
      filled dense flat
      class="rounded-0 mr-2"
      background-color="grey400"
      hide-details
      placeholder="Field"
      style="max-width: 200px;"
    />

    <v-combobox
      v-if="isArray"
      v-model="form.value"
      :items="[]"
      solo flat small-chips multiple
      clearable dense
      hide-details
      class="rounded-0 mr-2"
      background-color="grey600"
      placeholder="Edit array"
      label="Edit array"
    />
    <v-text-field
      v-else
      v-model="form.value"
      filled dense flat class="rounded-0 mr-2"
      placeholder="Value"
      hide-details
      background-color="grey600"
    />

    <div class="d-flex justify-end">
      <v-btn
        color="primary700" tile height="42"
        :ripple="false" :loading="loading"
        @click="onSet"
      >
        Set
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      field: null,
      value: null,
    },
  }),
  computed: {
    fields() {
      return ['betRequirement', 'xp', 'nameOverride', 'tradeUrl', 'withdrawDailyLimit', 'claimedLevelRewards']
    },
    isArray() {
      return Array.isArray(this.form.value)
    },
  },
  watch: {
    'form.field': function fn(val) {
      this.form.value = this.user?.[val] ?? null
    },
  },
  methods: {
    async onSet() {
      const { form } = this

      this.$emit('set', {
        field: form.field,
        value: form.value,
        steamid: this.user.steamid,
      })
    },
  },
}
</script>
