<template>
  <div class="d-flex">
    <v-text-field
      v-model.number="form.amount"
      filled dense flat class="rounded-0 mr-2"
      placeholder="Amount"
      hide-details
      background-color="grey600"
      style="max-width: 170px;"
    />

    <v-text-field
      v-model="form.reason"
      filled dense flat class="rounded-0 mr-2"
      placeholder="Reason (user sees this)"
      hide-details
      background-color="grey600"
    />

    <div class="d-flex justify-end">
      <v-btn
        color="primary700" tile height="42"
        :ripple="false" :loading="loading"
        :disabled="form.amount === 0"
        @click="onCredit"
      >
        {{ form.amount > 0 ? 'Credit' : 'Deduct' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      amount: null,
      reason: '',
    },
  }),
  computed: {},
  methods: {
    async onCredit() {
      this.$emit('credit', {
        amount: this.form.amount || 0,
        reason: this.form.reason,
        steamid: this.user.steamid,
      })
    },
  },
}
</script>
