<template>
  <div v-if="item.filler" class="item filler" />
  <div
    v-else
    class="item"
    :style="{ '--color': color }"
    :class="{ selected: item.selected }"
    @click="onClick"
  >
    <v-img
      :transition="false"
      :aspect-ratio="1"
      :src="`https://community.cloudflare.steamstatic.com/economy/image/${item.image}/164x164`"
      contain
    />
    <div class="inner">
      <!-- item attributes -->
      <div class="price lh-1 d-flex flex-center-y">
        <scrap :amount="item.price || 0" />

        <span
          v-show="item.selected && item.amountSelected > 1"
          class="ml-2 font-weight-bold lh-1"
        >
          (x{{ amountSelected }})
        </span>
      </div>

      <!-- hover overlay -->
      <div class="meta">
        <div class="item-info">
          <div
            :style="{'--color': color}"
            class="name mb-1 font-weight-bold"
          >
            {{ item.name | truncate(50) }}
          </div>
        </div>

        <div v-if="item.amount > 1" class="amount-input pb-3 d-flex justify-center">
          <v-btn
            color="blue700 blue100--text"
            class="unwidth px-2"
            x-small :ripple="false" tile depressed
            @click.stop="onDecreaseAmount"
          >
            <fai :icon="['fas', 'minus']" />
          </v-btn>

          <div
            class="px-3 grey800 font-weight-bold text-caption"
            @click.stop="onResetAmount"
          >
            {{ amountSelected }}x
          </div>

          <v-btn
            color="blue700 blue100--text"
            class="unwidth px-2"
            x-small :ripple="false" tile depressed
            @click.stop="onIncreaseAmount"
          >
            <fai :icon="['fas', 'plus']" />
          </v-btn>
        </div>
      </div>

      <!-- stock info -->
      <div class="item-stock-info text-body-2 d-flex align-center justify-end pa-2">
        <v-tooltip
          top open-delay="200"
          content-class="rounded-0"
          color="grey700"
          transition="v-scale-sorta"
        >
          <template #activator="{on}">
            <fai
              class="item-flag"
              :class="item.inStock ? 'green400--text' : 'primary400--text'"
              :icon="['fad', 'arrow-alt-square-up']"
              fixed-width
              v-on="on"
            />
            <span v-if="item.inStock" class="ml-1 lh-1 mt-1 font-weight-bold green200--text" v-text="item.inStock" />
          </template>
          <span
            v-t="item.inStock ? 'common.item.in_stock' : 'common.item.not_in_stock'"
            class="text-caption"
          />
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    item() {
      return this.data
    },
    color() {
      const { item } = this
      return item.colors?.name ?? item.color
    },
    amountSelected() {
      return this.item.amountSelected
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick', this.item)
    },
    onDecreaseAmount() {
      if (this.amountSelected < 1) return
      this.$emit('onItemAmountChange', this.item, this.amountSelected - 1)
    },
    onIncreaseAmount() {
      if (this.amountSelected + 1 > this.item.amount) return
      this.$emit('onItemAmountChange', this.item, this.amountSelected + 1)
    },
    onResetAmount() {
      this.$emit('onItemAmountChange', this.item, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
$panelbg: rgba(grey(300), 0.75);

.item {
  position: relative;
  background-color: rgba($panelbg, 0.7);
  cursor: pointer;
  flex: 1;

  &::v-deep .v-image {
    position: relative;
    z-index: 2;
    margin: 10px;
  }

  .inner {
    overflow: hidden;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .price {
      position: absolute;
      top: 12px;
      left: 8px;
      text-shadow: 0 0 8px black;
      font-size: 14px;
    }

    .item-amount {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 16px 14px;
      color: $text;
      text-shadow: 0 0 8px black;
      font-size: 22px;
    }
  }

  .meta {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 8px;
    opacity: 0;
    background: rgba(grey(900), 0.8);
    transition: opacity 0.3s ease;
    justify-content: center;
    will-change: opacity;

    .item-info {
      width: 100%;
      opacity: 0;
      color: white;
      // font-family: $family-primary;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      user-select: none;
      transition: transform 0.3s ease, opacity 0.3s ease;
      transform: translateY(40px);

      .attr-small {
        font-size: 11px;
      }
    }

    .amount-input {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &:hover {
      opacity: 1;

      .item-info {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .item-stock-info {
    overflow: hidden;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;

    // span {
    //   ver
    // }
  }

  &.filler {
    // visibility: hidden;
    background-color: rgba($panelbg, 0.7);
    cursor: default;
  }

  &.disabled {
    cursor: no-drop;
  }

  &.selected {
    background-color: darken(blue(), 7%);

    &::before,
    &::after {
      display: none;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &::before {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    background: radial-gradient(var(--color), transparent);
    content: ' ';
  }
}
</style>
