<template>
  <div>
    <!-- user searching  -->
    <section class="mb-6">
      <div class="heading d-flex align-center mb-3">
        <h3 class="title">
          Search &amp; manage users
        </h3>
        <v-spacer />
        <v-text-field
          v-model="search"
          solo dense hide-details flat
          class="rounded-0 text-caption mr-2"
          placeholder="Search by name or steamid"
          background-color="grey800"
        />
        <v-btn
          color="grey300"
          tile small class="unwidth unheight align-self-stretch"
          :ripple="false" :loading="loading"
          @click="fetchApiData"
        >
          <fai :icon="['fas','sync']" class="grey100--text" />
        </v-btn>
      </div>

      <v-data-table
        class="rounded-0 data-table"
        :headers="headers"
        :items="entries"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
        :server-items-length="total"
        :loading="loading"
      >
        <template #item.name="{ item }">
          <div class="d-flex align-center">
            <UserAvatar :user="item" size="24" class="mr-2" />
            <Username :user="item">
              <template v-if="item.nameOverride" #suffix>
                <span class="text--secondary text-caption ml-1">({{ item.nameOverride }})</span>
              </template>
            </Username>
          </div>
        </template>
        <template #item.balance="{ value }">
          <scrap :amount="value" />
        </template>
        <template #item.invTotal="{ value }">
          <span>${{ value || 0 | toScrap }}</span>
        </template>
        <template #item.xp="{ item }">
          Lvl. {{ item.level }}
        </template>
        <template #item.createdAt="{ value }">
          <v-tooltip top color="primary500">
            <template #activator="{on}">
              <span v-on="on">{{ value | relativeTime('twitter') }}</span>
            </template>
            <span>{{ value | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </v-tooltip>
        </template>

        <template #item.actions="{ item }">
          <fai
            :icon="['fad', 'pencil']"
            class="primary500--text link"
            @click="inspectUser(item.steamid)"
          />
        </template>
      </v-data-table>
    </section>

    <!-- user editing -->
    <section v-if="inspectedUser" id="user-inspect">
      <v-row dense>
        <v-col cols="12" class="py-0">
          <h2 class="text-subtitle-1 text-uppercase primary500--text mb-1 font-weight-bold">
            Inspected User
          </h2>
        </v-col>

        <v-col cols="12" md="7">
          <div class="grey800 px-4 py-3">
            <div class="d-flex align-center">
              <UserAvatar :user="inspectedUser" size="64" class="mr-3" />
              <div class="flex-grow-1">
                <h4 class="text-body-2">
                  <Username :user="inspectedUser" no-flairs>
                    <template v-if="inspectedUser.nameOverride" #suffix>
                      <span class="text--secondary text-caption ml-1">({{ inspectedUser.nameOverride }})</span>
                    </template>
                  </Username>
                </h4>
                <p class="mb-0 grey200--text text-body-2">
                  {{ inspectedUser.steamid }}

                  <a
                    class="link grey100--text"
                    :href="`https://steamcommunity.com/profiles/${inspectedUser.steamid}`"
                    target="_blank" rel="noopener noreferrer"
                  >
                    <fai :icon="['fab', 'steam-symbol']" class="ml-1" />
                  </a>
                </p>
                <Scrap :amount="inspectedUser.balance" class="text-caption mr-1" />
                <v-tooltip top color="primary500">
                  <template #activator="{on}">
                    <span class="text-caption grey200--text" v-on="on">
                      ({{ inspectedUser.model.betReq | toScrap }})
                    </span>
                  </template>
                  <span>Bet Requirement</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="5">
          <div class="grey800 px-4 py-3 full-height d-flex flex-column justify-center">
            <h4 class="text-body-2 mb-2">
              <fai :icon="['fad', 'calendar-alt']" class="primary500--text mr-2" />
              <span class="grey200--text mr-2">Joined:</span>
              <v-tooltip top color="primary500">
                <template #activator="{on}">
                  <span v-on="on">{{ inspectedUser.createdAt | relativeTime('twitter') }}</span>
                </template>
                <span>{{ inspectedUser.createdAt | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
              </v-tooltip>
            </h4>

            <h4 class="text-body-2">
              <fai :icon="['fad', 'clock']" class="primary500--text mr-2" />
              <span class="grey200--text mr-2">Last login:</span>
              <v-tooltip top color="primary500">
                <template #activator="{on}">
                  <span v-on="on">{{ inspectedUser.lastLogin | relativeTime }}</span>
                </template>
                <span>{{ inspectedUser.lastLogin | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
              </v-tooltip>
            </h4>
          </div>
        </v-col>

        <v-col cols="12">
          <div class="grey800 px-4 py-3">
            <h6 class="text-body-2 blue200--text">
              Level {{ inspectedUser.model.level }}
              <span class="blue500--text text-caption"> ({{ (inspectedUser.xp || 0) | toLocaleInt }} EXP)</span>
            </h6>
            <UserXPBar :xp="inspectedUser.xp" />
          </div>
        </v-col>

        <v-col cols="12" class="mb-3">
          <v-expansion-panels accordion flat tile>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey800">
                User Stats
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey800">
                <AdminUserStats :stats="inspectedUser.stats" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col cols="12" class="mb-3">
          <h3 class="text-body-1 text-uppercase primary500--text mb-1 font-weight-bold">
            Balance crediting/deducing
          </h3>

          <div class="grey800 px-4 py-5">
            <AdminUserBalance
              :user="inspectedUser"
              :loading="loading"
              @credit="onAddOrSubtractBalance"
            />
          </div>
        </v-col>

        <v-col cols="12" class="mb-3">
          <h3 class="text-body-1 text-uppercase primary500--text mb-1 font-weight-bold">
            Banning
          </h3>

          <AdminUserBan
            :user="inspectedUser"
            :loading="loading"
            @ban="onBanUser"
            @unban="onUnbanUser"
          />
        </v-col>

        <v-col cols="12" class="mb-3">
          <h3 class="text-body-1 text-uppercase primary500--text mb-1 font-weight-bold">
            Role &amp; Permissions
          </h3>

          <AdminUserPermissions
            :user="inspectedUser"
            @update="onUpdateUserPerms"
          />
        </v-col>

        <v-col cols="12" class="mb-3">
          <h3 class="text-body-1 text-uppercase primary500--text mb-1 font-weight-bold">
            Misc. property setting
          </h3>

          <AdminUserPropEdit
            :user="inspectedUser"
            @set="onUserPropEdit"
          />
        </v-col>

        <v-col cols="12" class="mb-3">
          <h3 class="text-body-1 text-uppercase primary500--text mb-1 font-weight-bold">
            Edit user flairs
          </h3>

          <AdminUserFlairs
            :value="inspectedUser.flairs"
            :flairs="flairs"
            @set="onUserSetFlairs"
          />
        </v-col>

        <v-col cols="12" class="mb-3">
          <h3 class="text-body-1 text-uppercase primary500--text mb-1 font-weight-bold">
            Inventory
          </h3>

          <v-expansion-panels accordion flat tile class="mb-3">
            <v-expansion-panel>
              <v-expansion-panel-header color="grey800">
                User Inventory
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey800">
                <AdminUserInventory :steamid="inspectedUser.steamid" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels accordion flat tile>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey800">
                Credit items
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey800">
                <AdminUserGiveItems :steamid="inspectedUser.steamid" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col cols="12" md="6" class="mb-3">
          <v-card
            class="grey600 link pa-4 d-flex align-center"
            :ripple="false"
            @click="onUserTransactionsClick(inspectedUser.steamid)"
          >
            <fai :icon="['fad', 'list']" class="green400--text mr-3" />
            <h3 class="text-body-2 text-uppercase font-weight-semibold lh-1 green200--text">
              User Transactions
            </h3>
            <v-spacer />
            <fai :icon="['fad', 'external-link-square-alt']" class="green500--text" />
          </v-card>
        </v-col>

        <v-col cols="12" md="6" class="mb-3">
          <v-card
            class="grey600 link pa-4 d-flex align-center"
            :ripple="false"
            @click="onUserBetsClick(inspectedUser.steamid)"
          >
            <fai :icon="['fad', 'list']" class="blue400--text mr-3" />
            <h3 class="text-body-2 text-uppercase font-weight-semibold lh-1 blue200--text">
              User Bets
            </h3>
            <v-spacer />
            <fai :icon="['fad', 'external-link-square-alt']" class="blue500--text" />
          </v-card>
        </v-col>

        <v-col cols="12" class="mb-3">
          <v-expansion-panels accordion flat tile>
            <v-expansion-panel @click="onGetUserCryptoAddresses(inspectedUser.steamid)">
              <v-expansion-panel-header color="grey600">
                <div class="flex-grow-1 d-flex align-center text-body-2">
                  <fai :icon="['fad', 'coins']" class="blue400--text mr-3" />
                  <span class=" text-uppercase font-weight-semibold lh-1 blue200--text">
                    User Crypto Addresses
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey600">
                <v-sheet v-for="address in cryptoAddresses" :key="address._id" class="grey700 px-4 py-3 mb-2">
                  <div class="d-flex align-center mb-2">
                    <cryptoicon v-if="address.coinCode" :symbol="address.coinCode" size="1em" class="mr-2" />
                    <span class="text-capitalize mr-2">{{ address.coin }}</span>
                    <span
                      class="text-caption lh-1 font-weight-bold text-uppercase px-2 py-1"
                      :class="[address.addressType === 'withdraw' ? 'green800 green200--text' : 'blue800 blue200--text']"
                      v-text="address.addressType"
                    />
                    <v-spacer />
                    <span class="text-caption text--secondary">
                      {{ address.createdAt | toDateFormat('YYYY-MM-DD HH:mm:ss') }}
                    </span>
                  </div>

                  <div
                    class="d-inline-block text-caption grey500 px-2 py-1 blue100--text mb-2"
                    v-text="address.address"
                  />

                  <h5 class="text-body-2 text--secondary">
                    Address name: {{ address.name }}
                  </h5>
                </v-sheet>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col cols="12" class="mb-3">
          <v-expansion-panels accordion flat tile>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey600">
                <div class="flex-grow-1 d-flex align-center text-body-2">
                  <fai :icon="['fad', 'gift']" class="blue400--text mr-3" />
                  <span class=" text-uppercase font-weight-semibold lh-1 blue200--text">
                    User Rewards
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey600">
                <AdminUserRewards :steamid="inspectedUser.steamid" class="mb-6" />

                <v-card tile color="grey700" class="pa-6">
                  <AdminUserRewardForm v-model="userRewardForm" />
                  <div class="text-right mt-2">
                    <v-btn color="green700 green100--text" tile @click="onCreditUserReward">
                      Credit reward
                    </v-btn>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col cols="12" class="mb-3">
          <v-expansion-panels accordion flat tile>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey600">
                Full user object
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey600">
                <pre class="user-obj" v-text="inspectedUser" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from '@/utils'
import { CRYPTO_CODES, LEVELS } from '@/utils/constants'

import User from '@/store/models/User'
import UserXPBar from '@/components/UserXPBar'
import AdminUserStats from './components/AdminUserStats'
import AdminUserBan from './components/AdminUserBan'
import AdminUserBalance from './components/AdminUserBalance'
import AdminUserPermissions from './components/AdminUserPermissions'
import AdminUserPropEdit from './components/AdminUserPropEdit'
import AdminUserFlairs from './components/AdminUserFlairs'
import AdminUserGiveItems from './components/AdminUserGiveItems'
import AdminUserInventory from './components/AdminUserInventory'
import AdminUserRewards from './components/AdminUserRewards'
import AdminUserRewardForm from './components/AdminUserRewardForm'

export default {
  components: {
    UserXPBar,
    AdminUserStats,
    AdminUserBan,
    AdminUserBalance,
    AdminUserPermissions,
    AdminUserPropEdit,
    AdminUserFlairs,
    AdminUserGiveItems,
    AdminUserInventory,
    AdminUserRewards,
    AdminUserRewardForm,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      search: '',
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        { text: 'User', value: 'name', align: 'start' },
        { text: 'Balance', value: 'balance' },
        { text: 'Inv value', value: 'invTotal' },
        {
          text: 'Lvl', value: 'xp', align: 'start', width: 80,
        },
        { text: 'Member since', value: 'createdAt', align: 'end' },
        { text: null, value: 'actions', sortable: false },
      ],
      entries: [],
      flairs: [],
      inspectedUser: null,
      cryptoAddresses: [],
      userRewardForm: {
        type: null,
        expiration: null,
        data: {},
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    search: {
      handler(val) {
        this.onSearchInput(val)
      },
      deep: true,
    },
    active(val) {
      if (val) this.getFlairs()
    },
  },
  created() {
    this.getFlairs()
  },
  methods: {
    onSearchInput: debounce(function fn(val) {
      this.fetchApiData()
    }, 500),
    async fetchApiData() {
      this.loading = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.users.get', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          search: this.search,
        })

        this.entries = entries.map(i => ({
          ...i,
          createdAt: new Date(i.createdAt).getTime(),
          level: this.getLevel(i.xp),
        }))
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async getFlairs() {
      try {
        const { entries, total } = await this.$socket.request('admin.flairs.list', {
          page: 1,
          sortBy: 'createdAt',
          asc: true,
          limit: 25,
        })

        this.flairs = entries
      } catch (error) {
        this.$toast.error(error.message, { title: 'Could not get flairs' })
      }
    },
    async inspectUser(steamid) {
      this.loading = true

      try {
        const user = await this.$socket.request('admin.user.get', steamid)

        this.inspectedUser = {
          ...user,
          claimedLevelRewards: user.claimedLevelRewards ?? [],
          model: new User(user),
        }

        this.cryptoAddresses = []

        await this.$nextTick()

        this.$vuetify.goTo('#user-inspect', {
          container: document.querySelector('.modal .content-ctn'),
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onBanUser(form) {
      this.loading = true

      try {
        await this.$socket.request('admin.user.ban', form)

        // refresh user cuz we want the bansInfo
        this.inspectUser(form.steamid)

        this.$toast.success('Ban applied successfully.')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onUnbanUser({ user, type }) {
      this.loading = true

      try {
        await this.$socket.request('admin.user.unban', {
          steamid: user.steamid,
          type,
        })

        // refresh user cuz we want the bansInfo
        this.inspectUser(user.steamid)

        this.$toast.success('Ban removed successfully.')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onAddOrSubtractBalance(form) {
      this.loading = true

      try {
        const { balance } = await this.$socket.request('admin.user.addOrSubtractBalance', form)

        this.inspectedUser.balance = balance
        this.$toast.success('Balance changed successfully.')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onUpdateUserPerms(form) {
      this.loading = true

      try {
        const { permissions, role } = await this.$socket.request('admin.user.setPermissions', form)

        this.inspectedUser.permissions = permissions
        this.inspectedUser.role = role

        this.$toast.success('Permissions updated successfully.')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onUserPropEdit(form) {
      this.loading = true

      try {
        const val = await this.$socket.request('admin.user.setProperty', form)

        this.inspectedUser[form.field] = val
        this.inspectedUser.model[form.field] = val // for computed properties like level

        this.$toast.success('Property updated successfully.')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onCreditUserReward() {
      this.loading = true

      try {
        await this.$socket.request('admin.user.rewards.credit', {
          ...this.userRewardForm,
          userId: this.inspectedUser.steamid,
        })

        this.$toast.success('Reward credited successfully.')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onGetUserCryptoAddresses(steamid = this.inspectedUser.steamid) {
      this.loading = true

      try {
        const addresses = await this.$socket.request('admin.user.cryptoAddresses', steamid)

        this.cryptoAddresses = addresses.map(i => ({
          ...i,
          coinCode: CRYPTO_CODES[i.coin],
        }))
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onUserSetFlairs(flairs) {
      this.loading = true

      try {
        const val = await this.$socket.request('admin.user.setFlairs', {
          steamid: this.inspectedUser.steamid,
          flairs,
        })

        this.inspectedUser.flairs = val
        this.inspectedUser.model.flairs = val // for computed properties like level

        this.$toast.success('Flairs updated successfully.')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    onUserTransactionsClick(steamid) {
      this.$emit('navigate', { tab: 'transactions', payload: { steamid } })
    },
    onUserBetsClick(steamid) {
      this.$emit('navigate', { tab: 'bets', payload: { steamid } })
    },
    getLevel(xp) {
      const nextLevel = LEVELS.findIndex(lvl => lvl > xp ?? 0)

      // we return nextLevel because index starts at 0 and first level is 1
      return (nextLevel !== -1 ? nextLevel : LEVELS.length) - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.user-obj {
  overflow: auto;
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
